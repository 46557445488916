import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = { class: "text-center mb-10" };
var _hoisted_2 = { class: "text-dark mb-3" };
var _hoisted_3 = { class: "text-gray-400 fw-bold fs-4" };
var _hoisted_4 = { class: "row" };
var _hoisted_5 = { class: "col col-12 col-xs-12 col-sm-6" };
var _hoisted_6 = { class: "fv-row mb-10" };
var _hoisted_7 = { class: "col col-12 com-xs-12 col-sm-6" };
var _hoisted_8 = { class: "fv-row mb-10" };
var _hoisted_9 = { class: "row" };
var _hoisted_10 = { class: "col col-12" };
var _hoisted_11 = { class: "fv-row mb-10" };
var _hoisted_12 = { class: "row" };
var _hoisted_13 = { class: "col col-12" };
var _hoisted_14 = { class: "fv-row mb-10" };
var _hoisted_15 = { class: "row" };
var _hoisted_16 = { class: "col col-12 col-xs-12 col-sm-6" };
var _hoisted_17 = { class: "fv-row mb-10" };
var _hoisted_18 = { class: "col col-12 com-xs-12 col-sm-6" };
var _hoisted_19 = { class: "fv-row mb-10" };
var _hoisted_20 = { class: "text-center" };
var _hoisted_21 = { key: 0 };
var _hoisted_22 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
var _hoisted_23 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" };
var _hoisted_24 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_field = _resolveComponent("base-field");
    var _component_base_button = _resolveComponent("base-button");
    var _component_base_social_login = _resolveComponent("base-social-login");
    var _component_Form = _resolveComponent("Form");
    var _component_base_col = _resolveComponent("base-col");
    return (_openBlock(), _createBlock(_component_base_col, {
        class: "col-sm-12 bg-white rounded mx-auto",
        style: { "max-width": "375px" }
    }, {
        default: _withCtx(function () { return [
            _createVNode(_component_Form, {
                class: "form w-100",
                onSubmit: _ctx.onSubmitRegister,
                "validation-schema": _ctx.registerValidate
            }, {
                default: _withCtx(function () { return [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("Register")), 1),
                        _createElementVNode("div", _hoisted_3, [
                            _createTextVNode(_toDisplayString(_ctx.$t("Already have an user account?")) + " ", 1),
                            _createElementVNode("span", {
                                class: "cursor_pointer primary_blue",
                                onClick: _cache[0] || (_cache[0] =
                                    //@ts-ignore
                                    function () {
                                        var args = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            args[_i] = arguments[_i];
                                        }
                                        return (_ctx.gotoLogin && _ctx.gotoLogin.apply(_ctx, args));
                                    }),
                                role: "button"
                            }, _toDisplayString(_ctx.$t("Sign In")), 1)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('First Name'),
                                    name: "firstname",
                                    type: "text",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('Last Name'),
                                    name: "lastname",
                                    type: "text",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('Email'),
                                    name: "email",
                                    type: "email",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('Phone'),
                                    name: "telephone",
                                    type: "text",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('Password'),
                                    name: "password",
                                    type: "password",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                                _createVNode(_component_base_field, {
                                    label: _ctx.$t('Password Confirmation'),
                                    name: "password_confirmation",
                                    type: "password",
                                    rules: "required"
                                }, null, 8, ["label"])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_base_button, {
                            showLoading: _ctx.loading,
                            class: "w-100 mb-5"
                        }, {
                            default: _withCtx(function () { return [
                                _createTextVNode(_toDisplayString(_ctx.$t("Continue")), 1)
                            ]; }),
                            _: 1
                        }, 8, ["showLoading"])
                    ]),
                    true
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("or")), 1),
                            _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("span", {
                                    class: "h2 text-uppercase",
                                    role: "button",
                                    onClick: _cache[1] || (_cache[1] =
                                        //@ts-ignore
                                        function () {
                                            var args = [];
                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                args[_i] = arguments[_i];
                                            }
                                            return (_ctx.gotoMobileRegister && _ctx.gotoMobileRegister.apply(_ctx, args));
                                        })
                                }, _toDisplayString(_ctx.$t("Register by Phone")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("or")), 1),
                            _createVNode(_component_base_social_login)
                        ]))
                        : _createCommentVNode("", true)
                ]; }),
                _: 1
            }, 8, ["onSubmit", "validation-schema"])
        ]; }),
        _: 1
    }));
}
