import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import { Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import BaseField from "@/components/BaseField.vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "login-modal",
    components: { Form: Form, BaseField: BaseField },
    emits: ["submit", "google_submit"],
    setup: function () {
        var _this = this;
        var store = useStore();
        var submitButton = ref(null);
        var registerModalRef = ref(null);
        var loading = ref(false);
        var router = useRouter();
        //Create form validation object
        var registerValidate = Yup.object().shape({
            firstname: Yup.string().required("Required").label("First Name"),
            lastname: Yup.string().required("Required").label("Last Name"),
            telephone: Yup.string().min(9).required("Required").label("Phone"),
            email: Yup.string().email("Invalid").required("Required").label("Email"),
            password: Yup.string().min(6).required("Required").label("Password"),
            password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
        });
        //Form submit function
        var onSubmitRegister = function (values) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                submitLogin(values);
                return [2 /*return*/];
            });
        }); };
        var gotoLogin = function () {
            router.push({ name: "login" });
        };
        var gotoMobileRegister = function () {
            router.push({ name: "mobile-register" });
        };
        var submitLogin = function (values) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Clear existing errors
                        store.dispatch(Actions.LOGOUT);
                        loading.value = true;
                        return [4 /*yield*/, store
                                .dispatch(Actions.REGISTER, values)
                                .then(function () {
                                // hideModal(registerModalRef.value);
                                router.push({ name: "home" });
                            })
                                .catch(function (data) {
                                console.error(data);
                            })];
                    case 1:
                        _a.sent();
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            onSubmitRegister: onSubmitRegister,
            registerValidate: registerValidate,
            submitButton: submitButton,
            registerModalRef: registerModalRef,
            gotoLogin: gotoLogin,
            gotoMobileRegister: gotoMobileRegister,
            loading: loading,
        };
    },
});
